import React from "react";
import { useAuth } from "../Api/useAuth";

function Menue({ Icon, isOpen, text, top, bottom,onClick }) {
  const { logOut } = useAuth();
  return (
    <div
      onClick={onClick}
      className={`flex items-center gap-2 mb-${bottom} mt-${top} h-[50px] cursor-pointer hover:bg-gray-100 hover:shadow-[0px 3px 8px 0px #33333317] p-3 rounded-lg`}
    >
      <Icon
        className={
          isOpen ? "w-[27px] h-[27px] stroke-1" : "w-[40px] h-[40px] stroke-1"
        }
      />
      {isOpen && <p className="w-[100px] tracking-wide capitalize">{text}</p>}
    </div>
  );
}

export default Menue;
