import React, { useEffect, useState } from "react";
import Dashboardview from "./Dashboardview";
import Sidebar from "./Sidebar";
import Main from "./Main";
import Deposit from "./Deposit";
import Withdraw from "./Withdraw";
import Transactions from "./Transactions";
import { parseCookies } from "nookies";
import { useAuth } from "../Api/useAuth";

function Dashboard() {
  const cookies = parseCookies();
  const { userId } = cookies;
  const { GetUserInfo } = useAuth();
  useEffect(() => {
    if (!userId) {
      window.location.href = "/login";
    }
  }, [cookies, userId]);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [user, setUser] = useState(null);

  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    const getUser = async () => {
      try {
        const res = await GetUserInfo();
        if (res.data.status) {
          setUser(res.data.message);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getUser();
  }, []);

  if (!userId) {
    window.location.href = "/login";
    return null;
  } else {
    return (
      <div className="flex flex-row">
        <div
          className={
            !isSideBarOpen
              ? "basis-[5%] h-[100vh] transition ease-in duration-700  md:block"
              : "basis-[12%] h-[100vh] transition ease-in duration-700 md:block"
          }
        >
          <Sidebar open={setIsSideBarOpen} isOpens={isSideBarOpen} setActiveTab={setActiveTab} />
        </div>
        <div
          className={
            !isSideBarOpen
              ? "basis-[95%] border transition ease-in duration-700 "
              : "basis-[88%] border transition ease-in duration-700"
          }
        >
          <Dashboardview user={user && user} />
          {activeTab === 0 && (
            <div>
              <Main user={user && user} setAcitveTab={setActiveTab} />
            </div>
          )}
          {activeTab === 1 && (
            <div>
              <Deposit />
            </div>
          )}
          {activeTab === 2 && (
            <div>
              <Withdraw />
            </div>
          )}
          {activeTab === 3 && (
            <div>
              <Transactions />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard;
