import React from "react";
import Navbar from "../Components/Navbar";
import Features from "../Components/Features";
import Footer from "../Components/Footer";
import Office from "../Assets/Office.jpg";
import Indicies from "../Assets/Indices.jpg";
import { Link } from "react-router-dom";

function Indices() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#172554] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-4xl md:text-7xl font-bold">
              Bitinvest Indices
            </h1>
          </div>
        </div>

        <div className="py-16 w-full px-4 h-[90vh] md:h-[30vh] lg:h-[80vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                CFD Trades on Indices
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Use the liquid Dow Jones, NASDAQ, S&P and other indices in
                online trading.
              </p>
              <h4 className="underline font-bold text-blue-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Indicies}
              alt="img"
            />
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] md:h-[30vh] lg:h-[60vh] h-[110vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                More Than 15 Indices From All Over The World
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                The most liquid and volatile indices of the United States, Asia
                and Europe are available to choose from.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                Execution Of Deals Without Commissions
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Opening and closing orders is carried out without hidden fees
                and additional payments.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                Mobile Or Web Training
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                It is possible to trade CFDs on indices in one click via a
                mobile terminal and a web platform.
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-20 px-4 h-[110vh] md:h-[40vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-blue-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                More About Indices Trading
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Indices are a category of financial instruments that display the
                average value of a group of stocks. The indices can include any
                number of securities of companies in the same field of activity.
                Through this combination, it is possible to track the dynamics
                of individual financial markets industry, real estate,
                information technologies, and others.
              </p>

              <h4 className="underline font-bold text-blue-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Indices;
