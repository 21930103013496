import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// w-65 h-60 absolute mx-10 my-[300px] md:mx-auto md:right-60 md:bottom-40 md:w-100 md:h-80
