import React from "react";
import {
  Input,
  Select,
} from "@chakra-ui/react";
import { FcCurrencyExchange } from "react-icons/fc";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Withdraw({ user }) {

  const onWithdrawl = ()=>{
    if(user?.canWithDrawl){
      toast.info('An Email Would be sent to you. with Detailed Instructions for Withdrawal Process')
    }else{
      toast.error('Attention: Minimum withdrawal amount required is 1500 TRX for both USDT and TRX (Tron).')
    }
  }

  return (
    <>
        <h1 className="ml-[20px] md:ml-[100px] lg:ml-[100px] mt-[50px] font-bold text-2xl md:text-4xl lg:text-4xl">Withdraw Crypto</h1>
        <div className="ml-[50px] md:ml-[150px] lg:ml-[150px] mt-[50px] w-[60vw] md:w-[50vw] lg:w-[50vw]">
            <p className="mb-[10px] font-bold text-xl">Email</p>
            <Input type="email" className="mb-[25px]" />
            <p className="mb-[10px] font-bold text-xl">Password</p>
            <Input type="password" className="mb-[25px]" />
            <p className="mb-[10px] font-bold text-xl">Select Currency</p>
            <Select
                icon={<FcCurrencyExchange />}
                placeholder="Select Currency"
                variant="filled"
            >
                <option value="USDT">USDT (TRC 20)</option>
                <option value="Bitcoin">Bitcoin</option>
                <option value="Ethereum">Ethereum (ERC 20)</option>
                <option value="Tron">Tron (TRC 20)</option>
            </Select>
            <p className="mt-[25px] mb-[10px] font-bold text-xl">Amount</p>
            <Input type="text" className="mb-[25px]" />
            <p className="mb-[10px] font-bold text-xl">Wallet Address</p>
            <Input type="text" className="mb-[25px]" />
            <button
                onClick={onWithdrawl}
                className="bg-[#2e59d9] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[30px] cursor-pointer"
            >
                Withdraw
            </button>
        </div>
    </>
  );
}

export default Withdraw;
