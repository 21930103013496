import React, { useState } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Link } from "react-router-dom";

function FeatureCards() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full py-[6rem] px-4 ">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="features"
      >
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-blue-900">
            Short-term Plan
          </h1>

          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3">
            <span>$</span>300
          </h1>

          <p className="text-[rgb(111,111,111)] mx-8 my-5">Minimum Deposit</p>

          <h1 className="text-blue-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline">
            Features
          </h1>

          <p className="p-4 text-[rgb(111,111,111)] font-bold">
            Short-term Investment starts from Three Hundred Dollars ($300) and
            Five hundred Dollars ($500) invested amount in return in (1) Week.
          </p>

          <button className="my-6 bg-blue-500 hover:bg-[#01519e] hover:text-white rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">Invest Now</Link>
          </button>
        </div>

        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-blue-900">
            Long-term Plan
          </h1>

          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3">
            <span>$</span>1000
          </h1>

          <p className="text-[rgb(111,111,111)] mx-8 my-5">Minimum Deposit</p>

          <h1 className="text-blue-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline">
            Features
          </h1>

          <p className="font-bold text-[rgb(111,111,111)] p-4">
            One thousand Dollars ($1,000.00) and above. The invested amount from
            Ten thousand Dollars ($10,000.00) will be receive a (22%) Return on
            investments after the investment time frame ends Earnings will be
            deposited to the client's provided payment account in ( 1 ) month.
          </p>

          <button className="my-6 bg-blue-500 hover:bg-[#01519e] hover:text-white rounded-xl p-4 w-[200px] mx-auto">
            <Link to="/register">Invest Now</Link>
          </button>
        </div>

        {/* <div  className='shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100'>
        <h1 className='font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-blue-900'>Professional</h1>

        <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3'><span>$</span>2500</h1>

        <p className='text-[rgb(111,111,111)] mx-8 my-5'>Minimum Deposit</p>
        
        <h1 className='text-blue-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline'>Features</h1>

        <ul>

          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Starting oppurtunity for online trading of various financial instruments.</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Leverage 1:20</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Insurance</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Instant Execution</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Protection</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10006;24/7 Support</p></li>
        </ul>

        <button className='my-6 bg-blue-500 hover:bg-[#01519e] hover:text-white rounded-xl p-4 w-[200px] mx-auto'><Link to='/register'>Invest Now</Link></button>
        </div>

        
      

      
        <div  className='shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100'>
        <h1 className='font-bold text-3xl sm:text-4xl md:text-5xl mx-8 text-blue-900'>Premium</h1>

        <h1 className='text-3xl sm:text-4xl md:text-5xl font-bold mx-8 my-3'><span>$</span>5000</h1>

        <p className='text-[rgb(111,111,111)] mx-8 my-5'>Minimum Deposit</p>
        
        <h1 className='text-blue-900 font-bold text-2xl sm:text-3xl md:text-4xl mx-8 my-3 underline'>Features</h1>

        <ul>

          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Starting oppurtunity for online trading of various financial instruments.</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Leverage 1:20</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Insurance</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Instant Execution</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;Deposit Protection</p></li>
          <li><p className='font-bold text-[rgb(111,111,111)] my-5 mx-8'>&#10004;24/7 Support</p></li>
        </ul>

        <button className='my-6 bg-blue-500 hover:bg-[#01519e] hover:text-white rounded-xl p-4 w-[200px] mx-auto'><Link to='/register'>Invest Now</Link></button>
        </div> */}
      </div>
    </div>
  );
}

export default FeatureCards;
