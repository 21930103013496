import React from "react";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from "@chakra-ui/react";


function Transactions({ user }) {
    const depositStyle = { color: "green" };
    const withdrawalStyle = { color: "red" };

    return (
        <>
            <h1 className="ml-[100px] mt-[50px] font-bold text-4xl">Transactions</h1>
            <div className="ml-[100px] mt-[50px]">
                <TableContainer>
                        <Table variant="simple">
                        <TableCaption>Bank Transaction Details</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Transaction ID</Th>
                                <Th>Amount</Th>
                                <Th>Date</Th>
                                <Th>Time</Th>
                                <Th>Transaction Type</Th>
                                <Th>Receiver Name</Th>
                                <Th>Account</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>W1X2C3V</Td>
                                <Td>$180.00</Td>
                                <Td>2022-11-30</Td>
                                <Td>9:30 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Thomas Green</Td>
                                <Td>XXX-XXXX-2345</Td>
                            </Tr>
                            <Tr>
                                <Td>B4N5M6L</Td>
                                <Td>$95.00</Td>
                                <Td>2022-11-29</Td>
                                <Td>8:15 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Susan Smith</Td>
                                <Td>XXX-XXXX-6789</Td>
                            </Tr>
                            <Tr>
                                <Td>K9J8H7G</Td>
                                <Td>$400.00</Td>
                                <Td>2022-11-28</Td>
                                <Td>10:52 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Richard Wilson</Td>
                                <Td>XXX-XXXX-0123</Td>
                            </Tr>
                            <Tr>
                                <Td>W1X2C3V</Td>
                                <Td>$180.00</Td>
                                <Td>2022-11-28</Td>
                                <Td>9:30 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Thomas Green</Td>
                                <Td>XXX-XXXX-2345</Td>
                            </Tr>
                            <Tr>
                                <Td>B4N5M6L</Td>
                                <Td>$95.00</Td>
                                <Td>2022-11-28</Td>
                                <Td>8:15 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Susan Smith</Td>
                                <Td>XXX-XXXX-6789</Td>
                            </Tr>
                            <Tr>
                                <Td>F2G3H4J</Td>
                                <Td>$70.00</Td>
                                <Td>2022-11-27</Td>
                                <Td>4:18 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Mary Taylor</Td>
                                <Td>XXX-XXXX-4567</Td>
                            </Tr>
                            <Tr>
                                <Td>K9J8H7G</Td>
                                <Td>$400.00</Td>
                                <Td>2022-11-27</Td>
                                <Td>10:52 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Richard Wilson</Td>
                                <Td>XXX-XXXX-0123</Td>
                            </Tr>
                            <Tr>
                                <Td>F2G3H4J</Td>
                                <Td>$70.00</Td>
                                <Td>2022-11-27</Td>
                                <Td>4:18 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Mary Taylor</Td>
                                <Td>XXX-XXXX-4567</Td>
                            </Tr>
                            <Tr>
                                <Td>Y6U7I8O</Td>
                                <Td>$160.00</Td>
                                <Td>2022-11-26</Td>
                                <Td>2:50 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Joseph Miller</Td>
                                <Td>XXX-XXXX-8901</Td>
                            </Tr>
                            <Tr>
                                <Td>Y6U7I8O</Td>
                                <Td>$310.00</Td>
                                <Td>2022-11-26</Td>
                                <Td>2:45 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Joseph Miller</Td>
                                <Td>XXX-XXXX-8901</Td>
                            </Tr>

                            <Tr>
                                <Td>Q1W2E3R</Td>
                                <Td>$150.00</Td>
                                <Td>2022-11-27</Td>
                                <Td>11:30 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>William Brown</Td>
                                <Td>XXX-XXXX-1234</Td>
                            </Tr>
                            <Tr>
                                <Td>T5Y6U7I</Td>
                                <Td>$45.00</Td>
                                <Td>2022-11-27</Td>
                                <Td>10:15 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Elizabeth Green</Td>
                                <Td>XXX-XXXX-5678</Td>
                            </Tr>
                            <Tr>
                                <Td>O9P0A1S</Td>
                                <Td>$350.00</Td>
                                <Td>2022-11-26</Td>
                                <Td>9:52 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>James Wilson</Td>
                                <Td>XXX-XXXX-9012</Td>
                            </Tr>
                            <Tr>
                                <Td>D3F4G5H</Td>
                                <Td>$60.00</Td>
                                <Td>2022-11-26</Td>
                                <Td>3:18 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Patricia Taylor</Td>
                                <Td>XXX-XXXX-3456</Td>
                            </Tr>
                            <Tr>
                                <Td>J7K8L9Z</Td>
                                <Td>$280.00</Td>
                                <Td>2022-11-25</Td>
                                <Td>1:45 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Robert Miller</Td>
                                <Td>XXX-XXXX-7890</Td>
                            </Tr>
                            <Tr>
                                <Td>Y7T8F3E</Td>
                                <Td>$120.00</Td>
                                <Td>2022-11-25</Td>
                                <Td>8:30 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>John Doe</Td>
                                <Td>XXX-XXXX-1234</Td>
                            </Tr>
                            <Tr>
                                <Td>Z9X8C7V</Td>
                                <Td>$75.00</Td>
                                <Td>2022-11-24</Td>
                                <Td>10:15 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Jane Smith</Td>
                                <Td>XXX-XXXX-5678</Td>
                            </Tr>
                            <Tr>
                                <Td>B2N3M4L</Td>
                                <Td>$300.00</Td>
                                <Td>2022-11-24</Td>
                                <Td>9:52 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Robert Johnson</Td>
                                <Td>XXX-XXXX-9012</Td>
                            </Tr>
                            <Tr>
                                <Td>G5H6J7K</Td>
                                <Td>$50.00</Td>
                                <Td>2022-11-23</Td>
                                <Td>3:18 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Emily Davis</Td>
                                <Td>XXX-XXXX-3456</Td>
                            </Tr>
                            <Tr>
                                <Td>L8M9N0O</Td>
                                <Td>$250.00</Td>
                                <Td>2022-11-23</Td>
                                <Td>1:45 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Oliver Taylor</Td>
                                <Td>XXX-XXXX-7890</Td>
                            </Tr>
                            <Tr>
                                <Td>KB7TF3E</Td>
                                <Td>$22.00</Td>
                                <Td>2022-11-22</Td>
                                <Td>7:48 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Alice Becker</Td>
                                <Td>XXX-XXXX-0571</Td>
                            </Tr>
                            <Tr>
                                <Td>SX9YWQM</Td>
                                <Td>$54.00</Td>
                                <Td>2022-11-22</Td>
                                <Td>7:23 AM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Alice Becker</Td>
                                <Td>XXX-XXXX-0571</Td>
                            </Tr>
                            <Tr>
                                <Td>AM9I5DW</Td>
                                <Td>$214.00</Td>
                                <Td>2022-11-21</Td>
                                <Td>1:52 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Hailey Grace</Td>
                                <Td>XXX-XXXX-8137</Td>
                            </Tr>
                            <Tr>
                                <Td>FCV9ILK</Td>
                                <Td>$320.00</Td>
                                <Td>2022-11-21</Td>
                                <Td>12:18 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Meyer Jones</Td>
                                <Td>XXX-XXXX-6625</Td>
                            </Tr>
                            <Tr>
                                <Td>M5N0P7Q</Td>
                                <Td>$200.00</Td>
                                <Td>2022-11-20</Td>
                                <Td>5:45 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Samuele Johnson</Td>
                                <Td>XXX-XXXX-9012</Td>
                            </Tr>
                            <Tr>
                                <Td>D4U6V8Q</Td>
                                <Td>-$40.00</Td>
                                <Td>2022-11-20</Td>
                                <Td>2:00 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Michael Brown</Td>
                                <Td>XXX-XXXX-4321</Td>
                            </Tr>
                            <Tr>
                                <Td>H9J2M8N</Td>
                                <Td>-$20.00</Td>
                                <Td>2022-11-19</Td>
                                <Td>4:30 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Eva White</Td>
                                <Td>XXX-XXXX-2289</Td>
                            </Tr>
                            <Tr>
                                <Td>DGNDP8F</Td>
                                <Td>$94.00</Td>
                                <Td>2022-11-18</Td>
                                <Td>12:00 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Alice Becker</Td>
                                <Td>XXX-XXXX-0571</Td>
                            </Tr>
                            <Tr>
                                <Td>X5Y1Z9A</Td>
                                <Td>-$30.00</Td>
                                <Td>2022-11-18</Td>
                                <Td>12:00 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Bob Anderson</Td>
                                <Td>XXX-XXXX-5411</Td>
                            </Tr>
                            <Tr>
                                <Td>P2Q9L6W</Td>
                                <Td>-$75.00</Td>
                                <Td>2022-11-17</Td>
                                <Td>3:15 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Alicia Johnson</Td>
                                <Td>XXX-XXXX-9876</Td>
                            </Tr>
                            <Tr>
                                <Td>Z8K7R3F</Td>
                                <Td>$80.00</Td>
                                <Td>2022-11-16</Td>
                                <Td>2:39 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Eva White</Td>
                                <Td>XXX-XXXX-2289</Td>
                            </Tr>
                            <Tr>
                                <Td>VNQS4H5</Td>
                                <Td>$60.00</Td>
                                <Td>2022-11-16</Td>
                                <Td>2:03 PM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Bob Anderson</Td>
                                <Td>XXX-XXXX-5411</Td>
                            </Tr>
                            <Tr>
                                <Td>Z8K7R3F</Td>
                                <Td>-$25.00</Td>
                                <Td>2022-11-16</Td>
                                <Td>1:45 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Miguel Rodriguez</Td>
                                <Td>XXX-XXXX-1234</Td>
                            </Tr>
                            <Tr>
                                <Td>A3B9C1D</Td>
                                <Td>-$50.00</Td>
                                <Td>2022-11-15</Td>
                                <Td>2:30 PM</Td>
                                <Td style={withdrawalStyle}>Withdrawal</Td>
                                <Td>Giovanna Ferrari</Td>
                                <Td>XXX-XXXX-5142</Td>
                            </Tr>
                            <Tr>
                                <Td>H8X2Y4Z</Td>
                                <Td>$100.00</Td>
                                <Td>2022-11-14</Td>
                                <Td>10:00 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Miguel Rodriguez</Td>
                                <Td>XXX-XXXX-1234</Td>
                            </Tr>
                            <Tr>
                                <Td>A3B9C1D</Td>
                                <Td>$73.00</Td>
                                <Td>2022-11-14</Td>
                                <Td>5:43 AM</Td>
                                <Td style={depositStyle}>Deposit</Td>
                                <Td>Giovanna Ferrari</Td>
                                <Td>XXX-XXXX-5142</Td>
                            </Tr>
                        </Tbody>

                        <Tfoot>
                            <Tr>
                            <Th>Transaction ID</Th>
                            <Th>Amount</Th>
                            <Th>Date</Th>
                            <Th>Time</Th>
                            <Th>Transaction Type</Th>
                            <Th>Receiver Name</Th>
                            <Th>Account</Th>
                            </Tr>
                        </Tfoot>
                        </Table>
                </TableContainer>
            </div>
        </>
    );
}

export default Transactions;
