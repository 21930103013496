import React from "react";
import Compass from "../Assets/Compass.jpeg";
import { Link } from "react-router-dom";

function TradingSteps() {
  return (
    <div className="w-full py-16 px-4">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8"
        id="tradingsteps"
      >
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Compass}
          alt="img"
        />
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-blue-900 md:text-4xl sm:text-3xl text-2xl md:p-4">
            Join Bitinvest Invest Three Steps
          </h1>
          <div>
            <ol>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                  <span className="text-blue-900">1. Click Register:</span>{" "}
                  Click the "Register" button on the website to create a new
                  account.
                </p>
              </li>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                  <span className="text-blue-900">
                    2. Fill out the application form:
                  </span>{" "}
                  Provide the basic information needed to create your account.
                </p>
              </li>
              <li>
                <p className="font-bold text-[rgb(111,111,111)] p-2">
                  <span className="text-blue-900">3. Make Deposit:</span> Select
                  the account type and top-up your personal account with an
                  amount higher than the minimum deposit level of the selected
                  account type.
                </p>
              </li>
            </ol>
          </div>
          <h2 className="font-bold text-blue-900 p-3 underline">
            <Link to="/register">Register Now</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default TradingSteps;
