import React from "react";
import Megaphone from "../Assets/Megaphone.png";
import { Link } from "react-router-dom";

function Features() {
  return (
    <div
      className="w-full py-16 lg:h-[300px] text-white px-4 bg-[#01519e]"
      id="referral"
    >
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div>
          <h1 className="text-2xl sm:text-3xl md:text-4xl">
            Create an Account and get up to 5.0% commission for each referral.
          </h1>
          <p className="my-6 font-bold underline">
            <Link to="/register">Join Now</Link>
          </p>
        </div>
        <img
          className="mx-auto mt-[-50px] my-4 w-[200px]"
          src={Megaphone}
          alt="img"
        />
      </div>
    </div>
  );
}

export default Features;
