import React from "react";
import { Link } from "react-router-dom";
import Partner from "../Assets/Partnership.jpg";

function Partnership() {
  return (
    <div className=" w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-3xl sm:text-4xl md:text-6xl text-blue-900">
            Why You Should Invest With Us
          </h1>
          <p className="font-bold text-[rgb(111,111,111)] p-3">
            Our success is centered around miscellaneous core values. Values
            that include providing competitive brokerage fees through tight
            spreads, ensuring lightening-fast execution, access to advanced
            trading platforms with an extensive portfolio of financial products,
            and exceptional customer service.
          </p>
          <h2 className="underline font-bold text-blue-900 p-3">
            <Link to="/register">Get Started</Link>
          </h2>
        </div>
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Partner}
          alt="img"
        />
      </div>
    </div>
  );
}

export default Partnership;
