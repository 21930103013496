import React, { useState } from "react";
import {
  Select,
} from "@chakra-ui/react";
import { FcCurrencyExchange } from "react-icons/fc";
import "react-toastify/dist/ReactToastify.css";
import ethereumQRCode from "../Assets/ethereum_qr_code.jpg";
import usdtQRCode from "../Assets/usdt_qr_code.jpg";
import bitcoinQRCode from "../Assets/bitcoin_qr_code.jpg";
import tronQRCode from "../Assets/tron_qr_code.jpg";

function Deposit({ isOpen, onClose, user }) {
  const [currency, setcurrency] = useState(null);

  return (
    <>
        <h1 className="ml-[20px] md:ml-[100px] lg:ml-[100px] mt-[50px] font-bold text-2xl md:text-4xl lg:text-4xl">Deposit Crypto</h1>
        <div className="ml-[50px] md:ml-[150px] lg:ml-[150px] mt-[50px] w-[60vw] md:w-[50vw] lg:w-[50vw]">
            <p className="mb-[25px] font-bold text-xl">Select Coin</p>
            <Select
                icon={<FcCurrencyExchange />}
                placeholder="Select Currency"
                variant="filled"
                onChange={(e) => {
                    setcurrency(e.target.value);
            }}
            >
                <option value="USDT">USDT (TRC 20)</option>
                <option value="Bitcoin">Bitcoin</option>
                <option value="Ethereum">Ethereum (ERC 20)</option>
                <option value="Tron">Tron (TRC 20)</option>
            </Select>

            {currency && currency === "USDT" && (
                <>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">
                        Deposit Address
                    </p>
                    <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
                        TPYmzpxvZWbnPAZY4b7jY3m4NZCjPSutcU
                    </p>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">QR Code</p>
                    <img className="w-[150px]" src={usdtQRCode} alt="" />
                </>
            )}
            {currency && currency === "Bitcoin" && (
                <>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">
                        Deposit Address
                    </p>
                    <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
                        17TDQLPypBWe8LwemMxYP3FdKne5MGvseZ
                    </p>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">QR Code</p>
                    <img className="w-[150px]" src={bitcoinQRCode} alt="" />
                </>
            )}
            {currency && currency === "Ethereum" && (
                <>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">
                        Deposit Address
                    </p>
                    <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
                        0x4133ce7ffa57dca2a8fbf6434d7fa35414ddccd6
                    </p>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">QR Code</p>
                    <img className="w-[150px]" src={ethereumQRCode} alt="" />
                </>
            )}
            {currency && currency === "Tron" && (
                <>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">
                        Deposit Address
                    </p>
                    <p className="bg-blue-300 p-4 mt-4 rounded-sm ">
                        TPYmzpxvZWbnPAZY4b7jY3m4NZCjPSutcU
                    </p>
                    <p className="mt-[25px] mb-[25px] font-bold text-xl">QR Code</p>
                    <img className="w-[150px]" src={tronQRCode} alt="" />
                </>
            )}
        </div>
    </>
  );
}

export default Deposit;
