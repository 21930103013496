import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Max from "../Assets/max.jpg";
import Portfolio2 from "../Assets/Portfolio-2.jpg";
import { FaChartPie, FaMicrochip, FaSearchDollar } from "react-icons/fa";
import faqdata from "./FAQinput";
import Footer from "../Components/Footer";

function About() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10 ">
        <div className="px-4 pb-10 mt-[66px] max-w-[1330px] mx-auto bg-[#172554] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              About Us At Bitinvest Invest
            </h1>
          </div>
        </div>
        <div className="py-16 w-full px-4 h-[90vh] md:h-[35vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <div className="flex flex-col justify-center">
              <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                Professional Brokers Putting Maximum Effort
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Traders and private investors from different regions of the
                world trust Bitinvest Invest, as the key to success is balancing
                trading conditions and strict compliance to international
                service standards.
              </p>
              <h4 className="underline font-bold text-blue-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Max}
              alt="img"
            />
          </div>
        </div>
      </div>
      <div className="py-20 px-4 h-[80vh] md:h-[30vh] lg:h-[60vh]">
        <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
          <img
            className="w-[500px] mx-auto my-4 rounded-[20px]"
            src={Portfolio2}
            alt="img"
          />
          <div className="flex flex-col justify-center">
            <h1 className="text-blue-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
              Professional Portfolio
            </h1>

            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              The unique terminal was created by a team of IT specialists,
              taking into account the needs of Bitinvest traders.
            </p>
            <p className="p-3 text-[rgb(111,111,111)] font-bold">
              The Software has built-in analysis instruments, an informative
              graph display, and a wide list of settings. You can trade using
              this platform, both from mobile devices and from a computer.
            </p>
          </div>
        </div>
      </div>

      <div className="py-20 px-4">
        <div className="max-w-[1240px] mx-auto py-10 px-4 grid lg:grid-cols-3 gap-10">
          <div>
            <h1 className="w-full text-4xl font-bold text-blue-900">
              Why Bitinvest Invest Stands Out When It Comes To Investment
            </h1>
            <p className="py-4 font-bold text-[rgb(111,111,111)]">
              In 2021, the number of active traders in the company increased by
              62%, which was the best result in the entire history of the
              brokers activity.
            </p>
          </div>
          <div className="lg:col-span-2 sm:flex sm:flex-row md:flex gap justify-between mt-6">
            <div>
              <FaMicrochip className="text-blue-400 text-center text-3xl mb-3" />
              <h1 className="font-bold text-2xl text-blue-900">
                Advanced Technology
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
                Request for deposit or withdrawals are processed automatically
                within ample time.
              </p>
            </div>

            <div>
              <FaSearchDollar className="text-blue-400 text-3xl mb-3" />
              <h1 className="font-bold text-center text-2xl text-blue-900">
                New Investment Programs
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
                Advanced order settings is just a function of few clicks, as
                they are important for subtle options.
              </p>
            </div>

            <div>
              <FaChartPie className="text-blue-400 text-3xl mb-3" />
              <h1 className="font-bold text-2xl text-blue-900">
                Elite Brokers Of 2024
              </h1>
              <p className="p-2 font-bold text-[rgb(111,111,111)]">
                New assets are constantly being added to the existing categories
                of financial instruments and lots more.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full px-4">
        <h1 className="text-blue-900 font-bold md:text-6xl sm:text-5xl text-5xl text-center pb-4">
          FAQ
        </h1>
        <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
          {faqdata.map((faqdata, id) => (
            <div
              key={id}
              className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100"
            >
              <h1 className="font-bold text-blue-900 text-2xl">
                {faqdata.question}
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                {faqdata.answer}
              </p>
            </div>
          ))}
        </div>
        <p className="text-center underline text-blue-900 font-bold pb-8">
          <Link to="/faq">View All</Link>
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default About;
