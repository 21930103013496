import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { FaEllipsisV, FaEthereum } from "react-icons/fa";
import PieComponent from "./PieComponent";
// import TradeViewChart from 'react-crypto-chart';
import ResizableComponent from "./ResizableComponent";
import "react-toastify/dist/ReactToastify.css";
import { BsCurrencyDollar, BsCurrencyBitcoin } from "react-icons/bs";
import trxLogo from "../Assets/trx_logo.svg"
const data = [
  {
    name: "Bitcoin",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Ethereum",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Litecoin",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Ripple",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Cardano",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Stellar",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
  {
    name: "Dogecoin",
    uv: Math.floor(Math.random() * 10000),
    pv: Math.floor(Math.random() * 10000),
    amt: Math.floor(Math.random() * 10000),
  },
];


function Main({ user, setAcitveTab }) {
  const containerRef = React.useRef(null);
  const [width, setWidth] = React.useState(0);
  console.log(user?.amountinDollars?.$numberDecimal,'user amount')
  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };
    
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ResizableComponent>
      <div className="pt-[25px] px-[25px] bg-[#fff] h-[100vh] ">
        <div className="flex items-center justify-between">
          <button
            onClick={() => setAcitveTab(1)}
            className="bg-[#2e59d9] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[30px] cursor-pointer"
          >
            Create a Deposit
          </button>
          <button
            onClick={() => setAcitveTab(2)}
            className="bg-[#2e59d9] h-[32px] rounded-[3px] text-white flex items-center justify-center px-[30px] cursor-pointer"
          >
            Withdraw
          </button>
        </div>
        <div className="mt-[25px]">
          <p className="font-bold">All fees are charged in TRX</p>
        </div>
        <div className="md:grid md:grid-cols-3 flex flex-wrap gap-1 md:gap-[30px] mt-[25px] pb-[15px]">
          <div className="h-[100px] w-full rounded-[8px] bg-white border-[1px]  flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out">
            <div>
              <h2 className="text-[#4E73DF] text-[12px] md:text-[17px] leading-[17px] font-bold">
              </h2>
              <h1 className=" text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]">
                {user
                  && 196580.23
                }
                {/* {user
                  ? user?.amountinDollars?.$numberDecimal.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })
                  : (0).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                })} */}
              </h1>
            </div>
            <BsCurrencyDollar fontSize={28} color="" />
          </div>
          <div className="h-[100px] w-full rounded-[8px] bg-white border-[1px]  flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out">
            <div>
              <h2 className="text-[#1cc88a] text-[12px] md:text-[17px] leading-[17px] font-bold">
            
              </h2>
              <h1 className="text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]">
                {user
                  ? 32
                  : 0
                }
              </h1>
            </div>
            <img src={trxLogo} alt="" className="w-[25px]" />
          </div>
          <div className="h-[100px] w-full rounded-[8px] bg-white border-[1px]  flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out">
            <div>
              <h2 className="text-[#1cc88a] text-[12px] md:text-[17px] leading-[17px] font-bold">
               
              </h2>
              <h1 className="text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]">
                {user
                  ? user?.amountinBTC?.$numberDecimal
                      .toLocaleString(undefined, {
                        style: "currency",
                        currency: "BTC",
                        minimumFractionDigits: 8,
                        maximumFractionDigits: 8,
                      })
                      .replace("BTC", "₿")
                  : 0}
              </h1>
            </div>
            <BsCurrencyBitcoin fontSize={28} color="" />
          </div>

          <div className="h-[100px] w-full rounded-[8px] bg-white border-[1px]  flex items-center justify-between px-[30px] cursor-pointer hover:shadow-lg transform hover:scale-[103%] transition duration-300 ease-out">
            <div>
              <h2 className="text-[#ED7117] text-[12px] md:text-[17px] leading-[17px] font-bold">
               
              </h2>
              <h1 className="text-[20px] leading-[24px] font-bold text-[#5a5c69] mt-[5px]">
                {user
                  ? user?.amountinETHUSDT?.$numberDecimal.toLocaleString(undefined, {
                      minimumFractionDigits: 8,
                      maximumFractionDigits: 8,
                    }) 
                  : 0}
              </h1>
            </div>
            <FaEthereum fontSize={28} color="" />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row mt-[22px] w-full gap-[30px]">
          <div className="basis-[50%] border bg-white shadown-md cursor-pointer rounded-[4px]">
            <div className="bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED] mb-[20px]">
              <h2>Earnings Overview</h2>
              <FaEllipsisV color="gray" className="cursor-pointer" />
            </div>
            <div ref={containerRef} className="font-bold w-full">
              <LineChart
                width={width}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </LineChart>
            </div>

            {/* <div className="w-full h-64">
      <Line data={data} options={options} />
                </div> */}
          </div>
          <div className="basis-[50%] border bg-[white]  cursor-pointer rounded-[4px]">
            <div className="bg-[#F8F9FC] flex items-center justify-between py-[15px] px-[20px] border-b-[1px] border-[#EDEDED]">
              <h2>Revenue Resources</h2>
              <FaEllipsisV color="gray" className="cursor-pointer" />
            </div>
            <div className="mx-auto flex items-center justify-center pl-[30px]">
              <PieComponent />
            </div>
          </div>
        </div>
        {/* 
        <div className='w-full pb-10'>
            <div className='max-w-[1240px] mx-auto grid'>
              <div className='truncate h-[100px]'>
              <h3>BTC/USDT</h3>
      <TradeViewChart
        containerStyle={{
          minHeight: '300px',
          minWidth: '400px',
          marginBottom: '30px',
        }}
        pair="BTCUSDT"
      />
      <h3>ADA/USDT</h3>
      <TradeViewChart
        containerStyle={{
          minHeight: '300px',
          minWidth: '400px',
          marginBottom: '30px',
        }}
        pair="ADAUSDT"
      />
      <h3>ETH/USDT</h3>
      <TradeViewChart
        containerStyle={{
          minHeight: '300px',
          minWidth: '400px',
          marginBottom: '30px',
        }}
        pair="ETHUSDT"
      />
              </div>
            </div>
        </div> */}
      </div>
    </ResizableComponent>
  );
}

export default Main;
