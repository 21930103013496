import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaEnvelope, FaKey, FaArrowLeft } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../Api/useAuth";
import { setCookie, parseCookies } from "nookies";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// Define validation schema using Yup
const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
});


function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);

  //get the signIn fro useAuth
  const { forgotPassword } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("clicked");
      setIsLoading(true);
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        const res = await forgotPassword(data);
        console.log(res, "grom res");
        if (res.data.status) {
          toast.success(res?.data?.message);
          window.location.href = "/login";
        } else {
          toast.error(res?.data?.message);
        }
        setIsLoading(false);
      } catch (err) {
        toast.error(err?.message);
        console.log(err);
        setIsLoading(false);
      }
    },
  });

  
  return (
    <div>
      <div className="w-full text-blue-900 bg-[#172554] py-[4rem]">
        <div className="flex relative">
          <Link to="/login">
            <FaArrowLeft className="text-white absolute mx-2 mt-1" />
            <h1 className="underline 2xl text-white mx-8 font-bold">Go Back</h1>
          </Link>
        </div>
        <h1 className="text-4xl text-white mx-auto flex justify-center mb-10 font-bold">
          <Link to="/">Bitinvest </Link>
        </h1>
        <div className="w-[300px] md:w-[500px] rounded-lg px-4 mx-auto pt-10 pb-10 bg-gray-200 ">
          <h1 className="text-blue-900 font-bold text-2xl mx-auto flex justify-center pb-8">
            Forgot Password
          </h1>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col justify-center"
          >
            <h1 className="mx-auto font-bold pt-4 pb-6">Email</h1>
            <FaEnvelope className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.email}</p>
            <input
              className="mx-auto p-2  w-[200px] md:w-[300px]"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="name@gmail.com"
            />

            <h1 className="mx-auto font-bold pt-4 pb-6">Password</h1>
            <FaKey className="mx-auto mb-3" />
            <p className="text-red-500 text-center">{formik.errors.password}</p>
            <input
              className="mx-auto p-2  w-[200px] md:w-[300px]"
              placeholder="Insert Password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              type="password"
            />

            <h1 htmlFor="confirm_pwd" className="mx-auto font-bold pt-4 pb-6">
              Confirm Password
            </h1>

            <FaKey className="mx-auto mb-3" />
            <p className="text-red-500 text-center">
              {formik.errors.confirmPassword}
            </p>
            <input
              className="mx-auto p-2  w-[200px] md:w-[300px"
              placeholder="Confirm Password"
              id="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              type="password"
              required
            />

            <button
              type="submit"
              className="bg-[#01519e] mx-auto w-[150px] md:w-[250px] mt-10 mb-6 text-2xl p-2 rounded-[20px] text-white hover:bg-blue-400 hover:text-[#172554]"
            >
              {isLoading ? "loading" : "Reset Password"}
            </button>
            <p className="text-black mx-auto">
              Copyright 2023 Bitinvest Invest All Rights Reserved
            </p>
            <ToastContainer />
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
