import {
  LuChevronRight,
  LuChevronLeft,
  LuLayoutDashboard,
  LuCrown,
  LuLogOut,
  LuLibrary,
  LuArrowDown,
  LuUpload,
} from "react-icons/lu";
import Menue from "./menue";
import { useAuth } from "../Api/useAuth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function Sidebar({ open, isOpens, setActiveTab }) {
  const { logOut } = useAuth();

  const handleTab = (index) => {
    setActiveTab(index)
  }

  return (
    <div className="h-[100vh] flex flex-col items-center p-5">
      <Menue
        Icon={LuLayoutDashboard}
        isOpen={isOpens}
        text="Dashboard"
        top={0}
        bottom={10}
        onClick={() => handleTab(0)}
      />

      <Menue
        Icon={LuArrowDown}
        isOpen={isOpens}
        text="Deposit"
        top={0}
        bottom={10}
        onClick={() => handleTab(1)}
      />

      <Menue
        Icon={LuUpload}
        isOpen={isOpens}
        text="Withdraw"
        top={0}
        bottom={10}
        onClick={() => handleTab(2)}
      />

      <Menue
        Icon={LuLibrary}
        isOpen={isOpens}
        text="Transactions"
        top={0}
        bottom={4}
        onClick={() => handleTab(3)}
      />
      <Menue
        Icon={LuCrown}
        isOpen={isOpens}
        text="upgrade"
        top={0}
        bottom={4}
        onClick={() => {
          toast.info(
            "Account upgrade are made automatically due to increase in user account balance"
          );
        }}
      />
      <Menue
        Icon={LuLogOut}
        isOpen={isOpens}
        text="logout"
        top={0}
        bottom={10}
        onClick={logOut}
      />

      {!isOpens && (
        <LuChevronRight
          onClick={() => {
            open(true);
          }}
          className="w-[40px] h-[40px] stroke-1 relative  transition ease-in duration-700"
        />
      )}
      {isOpens && (
        <div
          onClick={() => {
            open(false);
          }}
          className="flex items-center cursor-pointer gap-2 mb-4 h-[50px]"
        >
          <LuChevronLeft className="w-[27px] h-[27px] stroke-1 relative  transition ease-in duration-700" />
          <p className="w-[100px] tracking-wide capitalize">minimize</p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default Sidebar;
