const licenseData = [
  {
    id: 1,
    name: "Quotix Aggregator",
    text: "One of the world's largest aggregators of liquidity with accurate and stable data transfer to partner brokers.",
  },

  {
    id: 2,
    name: "JP Morgan",
    text: "A financial center that combines quotes from different sources and routes them directly to brokerage companies.",
  },

  {
    id: 3,
    name: "MiFID",
    text: "The European Financial Directive, which prescribes clear regulated rules for the operation of brokerage companies in Europe and other regions.",
  },

  {
    id: 4,
    name: "IFSC",
    text: "The presence of a license of this organization indicates compliance with clear regulations and control of transactions.",
  },

  {
    id: 5,
    name: "Golden Sachs",
    text: "One of the largest financial companies in the world, which provides access to certain trust management services, liquidity quotes and other financial instruments.",
  },

  {
    id: 6,
    name: "CySEC",
    text: "The Cyprus national regulator, which is characterized by the strictness of the regulations for checking partner brokerage companies.",
  },

  {
    id: 7,
    name: "ASIC",
    text: "Financial regulator, which licenses the companies from Australia and a few regions of the offshore zone.",
  },

  {
    id: 8,
    name: "MFSA",
    text: "The Maltese national regulator, which oversees the financial activities of licensed companies.",
  },
];

export default licenseData;
