import React from "react";
import Benefits from "./Benefits";
import Community from "./Community";
import FeatureCards from "./FeatureCards";
import Features from "./Features";
import Footer from "./Footer";
import Hero from "./Hero";
import InvestmentFactors from "./InvestmentFactors";
import Investors from "./Investors";
import MarketCards from "./MarketCards";
import Navbar from "./Navbar";
import Partnership from "./Partnership";
import Profits from "./Profits";
import Reviews from "./Reviews";
import TradingSteps from "./TradingSteps";
import Contract from "./Contract";

function Home() {
  return (
    <div>
      <Navbar />
      <Hero />
      <Investors />
      <Profits />
      <InvestmentFactors />
      <TradingSteps />
      <Benefits />
      <MarketCards />
      <Features />
      <FeatureCards />
      <Contract />
      <Partnership />
      <Community />
      <Reviews />
      <Footer />
    </div>
  );
}

export default Home;
