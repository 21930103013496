import React from "react";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Metal from "../Assets/Metals.jpg";
import Office from "../Assets/Office.jpg";
import { Link } from "react-router-dom";
import Features from "../Components/Features";

function Metals() {
  return (
    <div>
      <Navbar />
      <div className="w-full text-white pb-10">
        <div className="px-4 mt-[66px] max-w-[1330px] mx-auto pb-10 bg-[#172554] rounded-[50px]">
          <div className="flex justify-center">
            <h1 className="mt-8 mx-auto text-center text-4xl md:text-7xl font-bold">
              Metal Value on Bitinvest
            </h1>
          </div>
        </div>

        <div className="py-20 px-4 h-[90vh] md:h-[30vh] lg:h-[70vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8">
            <img
              className="w-[500px] mx-auto my-4 rounded-[20px]"
              src={Metal}
              alt="img"
            />
            <div className="flex flex-col justify-center">
              <h1 className="text-blue-900 font-bold md:text-6xl md:mx-3 sm:text-4xl text-3xl">
                CFD Trades on Metals
              </h1>

              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Trading precious metals is a great way to diversify your
                portfolio and earn on the predicted fluctuations in gold and
                silver prices.
              </p>

              <h4 className="underline font-bold text-blue-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
          </div>
        </div>

        <div className="w-full px-4 py-[4rem] md:h-[30vh] lg:h-[60vh] h-[110vh]">
          <div className="max-w-[1240px] mx-auto grid sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                Microlots Are Available
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Deals with precious metals are available even with a minimum
                deposit at the expense of microlots.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                Zero Slipperage
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                Real data is processed in modern data centers, which eliminates
                slippage.
              </p>
            </div>

            <div className="shadow-xl flex flex-col p-2 my-4 rouded-lg bg-blue-100">
              <h1 className="font-bold text-blue-900 text-2xl">
                Gold And Silver Trading
              </h1>
              <p className="font-bold text-[rgb(111,111,111)] p-3">
                It is possible to trade CFDs on the web terminal and mobile
                platforms with two liquid metals: silver and gold.
              </p>
            </div>
          </div>
        </div>
        <Features />

        <div className="py-16 w-full px-4 h-[110vh] md:h-[30vh] lg:h-[80vh]">
          <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl">
                More About Metal Trading
              </h1>
              <p className="p-3 text-[rgb(111,111,111)] font-bold">
                Precious metals are a full-fledged financial instrument that is
                used in international trade deals. Gold and platinum are
                alternatives to national currencies, so the prices of these
                assets are more predictable. On the world exchanges, deals with
                metals are carried out not directly, but in the format of
                futures.
              </p>
              <h4 className="underline font-bold text-blue-900 p-3">
                <Link to="/register" className="link">
                  Open Account
                </Link>
              </h4>
            </div>
            <img
              className="w-[600px] mx-auto my-4 rounded-[20px]"
              src={Office}
              alt="img"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Metals;
