import React from "react";

function Contract() {
  return (
    <div className="w-full py-[4rem] px-4 bg-gray-200">
      <div className="max-w-[1240px] mx-auto">
        <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Briefing and Clarifications
        </h1>
        <p className="font-bold my-4 p-4 ">
          If a client invests $10,000.00 they would get ($4,500.00) Four
          Thousand Five Hundred Dollars in profit. That is ($14,500.00) Fourteen
          Thousand, Five Hundred Dollars after a month. That means every single
          investment of $10,000.00 above gives a client profit of 45%, in
          return. You will be needed to sign as well in the amount invested.
        </p>

        <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Contract terms and conditions.
        </h1>
        <p className="font-bold my-4 p-4">
          The contract starts on the date the deposit is made, and ends in one
          month, as for Short term investment it ends in one week. This contract
          is only valid for one month/week. And after receiving payment of
          investment and profit the client can renew the contract if interested.
          Clients are granted a 5% Referral Commission on each of your
          referral's first Deposit.
        </p>

        <h1 className="text-blue-900 font-bold md:text-6xl sm:text-4xl text-3xl text-center">
          Payment terms and conditions
        </h1>
        <p className="font-bold my-4 p-4">
          Cash transactions are not required. All payments will be sent to a
          provided client bank or business account. The client shall have a
          personal or business account to receive the payment. Banks we partner
          with are as follows: BOA, CHASE BANK, CHIME BANK, ETC. A client can
          also have His/Her returns sent to a provided Bitcoin wallet. If a
          client uses any of the mentioned banks, they can get their investment
          profits sent straight to their accounts. All investors are responsible
          to pay SARS or tax on their profit returns as the law applies. The
          company Bitinvest LTD is not accountable for non-investors tax
          compliance with the law of any country.
        </p>
      </div>
    </div>
  );
}

export default Contract;
