import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Star from "../Assets/Bitinvest.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4  text-blue-400">
      <img width="64" height="64" src="https://img.icons8.com/external-others-cattaleeya-thongsriphong/64/external-bit-bitcoin-color-line-others-cattaleeya-thongsriphong-20.png" alt="external-bit-bitcoin-color-line-others-cattaleeya-thongsriphong-20" className="w-[64px] h-[64px]" />
      <h1 className="w-full text-4xl font-bold text-[#172554] mx-8">
        <Link to="/">Bitinvest</Link>
      </h1>
      <ul className="lg:flex w-full hidden">
        <li className="relative group">
          {/* Navbar dropdown */}
          <p className="p-4 hover:bg-[#01519e] hover:text-white transition-colors duration-300 rounded-full text-[#172554]">
            <Link to="/about">About</Link>
          </p>
          <ul className="absolute left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/license">License & Accreditations</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>
        <li className="relative group">
          <p className="p-4 hover:bg-[#01519e] hover:text-white transition-colors duration-300 rounded-full text-[#172554]">
            <Link to="/tradingconditions">Trading-Conditions</Link>
          </p>
          <ul className="absolute left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/faq">FAQ</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="relative group">
          <p className="p-4 hover:bg-[#01519e] hover:text-white transition-colors duration-300 rounded-full text-[#172554]">
            <Link to="/market">Market</Link>
          </p>
          <ul className="absolute left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/cryptocurrencies">Cryptocurrencies</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/commodities">Commodities</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/cryptomining">Cryptomining</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/metals">Metals</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/indices">Indices</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/stocks">Stocks</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="p-4 hover:bg-[#01519e] hover:text-white transition-colors duration-300 rounded-full text-[#172554]">
          <Link to="/register">Register</Link>
        </li>
        <li className="p-4 hover:bg-[#01519e] hover:text-white transition-colors duration-300 rounded-full text-[#172554]">
          <Link to="/login">Login</Link>
        </li>
      </ul>

      <div onClick={handleNav} className="block lg:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500"
            : "fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#0492c2] m-4">
          Bitinvest
        </h1>
        <li className="relative group">
          {/* Navbar dropdown */}
          <p className="p-4 border-b border-gray-600 hover:bg-[#01519e] hover:text-white transition-colors duration-300 ">
            <Link to="/about">About</Link>
          </p>
          <ul className="left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/license">License & Accreditations</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="relative group">
          <p className="p-4 border-b border-gray-600 hover:bg-[#01519e] hover:text-white transition-colors duration-300 ">
            <Link to="/tradingconditions">Trading-Conditions</Link>
          </p>
          <ul className=" left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px] z-1">
                <Link to="/faq">FAQ</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>

        <li className="relative group ">
          <p className="p-4 border-b border-gray-600 hover:bg-[#01519e] hover:text-white transition-colors duration-300 ">
            <Link to="/market">Market</Link>
          </p>
          <ul className=" left-0 hidden mt-2 space-y-2 bg-white text-black group-hover:block">
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/cryptocurrencies">Cryptocurrencies</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/commodities">Commodities</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/cryptomining">Cryptomining</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/metals">Metals</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/indices">Indices</Link>
              </p>
            </li>
            <li>
              <p className="block px-4 py-2 hover:bg-[#01519e] hover:text-white hover:rounded-[10px]">
                <Link to="/stocks">Stocks</Link>
              </p>
            </li>
            {/* Add more dropdown links as needed */}
          </ul>
        </li>
        <li className="p-4 border-b border-gray-600">
          <Link to="/register">Register</Link>
        </li>
        <li className="p-4">
          <Link to="/login">Login</Link>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
