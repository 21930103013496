import React from "react";
import { FaStar, FaStarHalf } from "react-icons/fa";

function Reviews() {
  return (
    <div className="w-full px-4 py-[4rem]">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-3 gap-8">
        <div className="shadow-xl flex flex-col p-2 my-4 rounded-lg bg-blue-100">
          <p className="font-bold my-3 text-[rgb(111,111,111)]">
            I had plans but my finances kept holding me back, everything changed
            when i joined Bitinvest Invest, yep! maximum payouts.
          </p>
          <h1 className="text-center text-blue-900 my-3 text-2xl font-bold">
            Michael Bornfield
          </h1>
          <h4 className="text-center text-blue-900 my-3">Investor</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
          </ul>
        </div>

        <div className="shadow-xl justify-center flex flex-col p-2 md:my-0 my-8 rounded-lg bg-blue-100">
          <p className="font-bold text-[rgb(111,111,111)]">
            Ever since i started earning money i've had issues handling it or
            setting my finances in order, but i came across Bitinvest Invest and
            that all changed.
          </p>
          <h1 className="text-center text-blue-900 my-3 text-2xl font-bold">
            Robert Pattinson
          </h1>
          <h4 className="text-center text-blue-900 my-3">Farmer</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStarHalf className="text-yellow-500" />
            </li>
          </ul>
        </div>

        <div className="shadow-xl justify-center flex flex-col p-2 my-4 rounded-lg bg-blue-100">
          <p className="font-bold my-3 text-[rgb(111,111,111)]">
            Bitinvest Invest will change your life guys, this is the most honest
            review you'll probably find. Get your trading to a point where you
            are financially free with Bitinvest Invest.
          </p>
          <h1 className="text-center text-blue-900 my-3 text-2xl font-bold">
            Catherine Buble
          </h1>
          <h4 className="text-center text-blue-900 my-3">Banker</h4>
          <ul className="flex justify-center">
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
            <li>
              <FaStar className="text-yellow-500" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
