import React from "react";
import { Link } from "react-router-dom";
import Profit from "../Assets/Profits.jpeg";

function Profits() {
  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img
          className="w-[500px] mx-auto my-4 rounded-[20px]"
          src={Profit}
          alt="img"
        />
        <div className="flex flex-col justify-center">
          <h1 className="font-bold text-blue-900 md:text-6xl sm:text-4xl text-3xl md:p-4">
            Maximise Your Profits Daily.
          </h1>
          <h2 className="underline font-bold text-blue-900 p-6">
            <Link to="/tradingconditions">Read More.</Link>
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Profits;
